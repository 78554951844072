.dropdown {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  :global(.dropdown-toggle::after) {
    display: none;
  }

  .item {
    svg {
      width: 1rem;
    }
  }
}
