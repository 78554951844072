.listItem {
  height: 60px;

  .deleteButton {
    display: none;
  }

  &:hover .deleteButton {
    display: block;
  }
}
