.card {
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
    }

    .button {
      width: 42px;

      .icon {
        width: 1rem;
      }
    }

    :global(.dropdown-toggle::after) {
      display: none;
    }
  }

  .list {
    overflow-y: auto;
  }
}

.dropdownItem {
  border-radius: 0px;
}
