$K_PANEL_Z_INDEX: 20;

.container {
  position: relative;
  display: grid;
  grid-template-rows: min-content auto;
  height: 100%;
  z-index: $K_PANEL_Z_INDEX;

  .content {
    overflow-y: auto;

    :global(.card) {
      border-top-color: rgb(247, 247, 247);
      border-radius: 0px;
    }

    :global(.card-header) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }

    :global(.list-group-item) {
      border-radius: 0 !important;
      border-right: none;
      border-left: none;
    }
  }

  .collapseToggle {
    position: absolute;
    z-index: $K_PANEL_Z_INDEX + 1;
    top: 50%;
    right: 0;
    transform: translate(100%, -50%);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-left: none;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    svg {
      width: 0.5rem;
    }
  }
}

.activeNavLink {
  background-color: rgb(247, 247, 247) !important;
  border-bottom-color: rgb(247, 247, 247) !important;
}
