.card {
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
    }
  }

  .list {
    overflow-y: auto;
  }
}

.listItem {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .moreButton {
    cursor: pointer;
    margin-left: 0.75rem;
    margin-right: -0.5rem;
  }
}
