.container {
  width: 100%;
  height: 100%;

  .card {
    height: 100%;

    .header {
      display: flex;
      align-items: center;

      .title {
        flex-grow: 1;
      }
    }
  }
}
