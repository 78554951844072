.container {
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;

  .form {
    padding: 0.75rem;
    background-color: white;
  }

  .results {
    background-color: white;
    overflow-y: auto;
  }
}
