.listItem {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .name {
    font-weight: 500;
  }

  .details {
    display: flex;
    color: var(--secondary);
    justify-content: space-between;
  }

  .moreButton {
    cursor: pointer;
    margin-left: 0.5rem;
    margin-right: -0.75rem;
  }

  &:hover .moreButton {
    visibility: visible;
    pointer-events: all;
  }
}
