.card {
  height: 100%;
  grid-template-rows: min-content auto;

  .header {
    display: flex;
    align-items: center;

    .title {
      flex-grow: 1;
    }
  }
}
