.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  height: 100%;
  width: 100%;

  .image {
    max-width: 100%;
    max-height: 100%;
  }
}
