$K_WIDTH: 25px;
$K_HEIGHT: 25px;

$K_WIDTH_HOVERED: 35px;
$K_HEIGHT_HOVERED: 35px;

.container {
  position: absolute;
  width: $K_WIDTH !important;
  height: $K_HEIGHT;
  left: -$K_WIDTH / 2;
  top: -$K_HEIGHT / 2;
}

.hovered {
  cursor: pointer;
  width: $K_WIDTH_HOVERED !important;
  height: $K_HEIGHT_HOVERED;
  left: -$K_WIDTH_HOVERED / 2;
  top: -$K_HEIGHT_HOVERED / 2;
}

$MENU_HEIGHT: 60px;
$MENU_WIDTH: 150px;
$GAP: 15px;

.contextMenu {
  display: flex;

  :global(.popover) {
    top: -$K_HEIGHT/2;
    left: $K_WIDTH/2;
  }

  :global(.btn) {
    border-color: rgb(235, 235, 235);
  }
}
