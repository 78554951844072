.item {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

.sessionListContainer {
  :first-child {
    border-top: none;
  }

  :last-child {
    border-bottom: none;
  }

  :global(.card) {
    border-top-color: rgb(247, 247, 247);
    border-radius: 0px;
  }

  :global(.list-group-item) {
    border-radius: 0 !important;
    border-right: none;
    border-left: none;
  }
}
