.travelModeButton {
  cursor: pointer;
  border-radius: 38px;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: lightblue;
}
